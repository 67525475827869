import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import enableTransactionTypes from '../assets/enable-transaction-types.png'
import setSpendingLimits from '../assets/set-spending-limits.png'

const SpendingLimits = () => {
  return (
    <>
        <Container className='how-to-activate' id='spendingLimits'>
            <Row>
                <Col md={12}>
                    <h1>How to <span className='cursiveText'>Set Usage & Spending Limits:</span> </h1>
                    <h3>Dhi CUB Visa Signature Card</h3>
                    <h6 className='listItem spending-limits-subheading'><sup>*</sup>e-Commerce transactions with not be available during the test program. </h6>
                </Col>
            </Row>
        </Container>
         <Container>
            <Row className='text-center'>
                <Col md={4} className='login'>
                    <h4>enable transaction type<sup>**</sup> </h4>
                    <Image src={enableTransactionTypes} className='img-fluid' />
                    <ul className='listItem'>
                        <li>Now move to the profile page again and choose spending 
                            limits to enable transactions of different types.
                        </li>
                        <li>
                            Click on the on/off option beside every type of
                            transaction you want to enable.
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='profile-page'>
                    <h4>Set Spending Limits</h4>
                    <Image src={setSpendingLimits} className='img-fluid' />
                    <ul className='listItem'>
                        <li>
                            Once you enable a transaction it will ask you to set a amount
                            limit for that particular type of transaction and confirm it.
                        </li>
                        <li>
                            This can be changed when ever required for the spending limits page.
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='spendingLimitsrightsideShape'>
                    <div className='spendingLimitssmallShape'></div>
                </Col>
            </Row>
            <Row className='text-center'>
                <Col md={8} >
                <div className='welcome-again-text'>
                    <p  className='listItem mt-4 mb-5'>
                       <strong>Your Card is now customized and ready for use as per your specific requirements! </strong> 
                       <br />
                       Welcome again, and we look forward to your usage of the Dhi CUB Visa Signature Card. 
                       If you have any difficulties with any of the instructions or if you run into any problems, 
                       please reach out to us using the “Contact Us” link below.
                    </p>
                    <button className='mb-5 mt-3'>
                        <a href=''>Contact us</a>
                    </button>
                </div>
                </Col>
            </Row>
         </Container>
    </>
  )
}

export default SpendingLimits