import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import changePin from '../assets/change-pin.png'
import confirmPin from '../assets/confirm-pin.png'

export const ChoosePin = () => {
  return (
    <>
        <Container className='how-to-activate' id='choosePin'>
            <Row>
                <Col md={12}>
                    <h1>How to <span className='cursiveText'>Choose PIN:</span> </h1>
                    <h3>Dhi CUB Visa Signature Card</h3>
                </Col>
            </Row>
        </Container>
         <Container>
            <Row className='text-center'>
                <Col md={4} className='login'>
                    <h4>change card pin</h4>
                    <Image src={changePin} className='img-fluid' />
                   <ul className='listItem'>
                        <li>Set a new Pin using the Change Card Pin option on the screen.</li>
                   </ul>
                </Col>
                <Col md={4} className='profile-page'>
                    <h4>confirm pin</h4>
                    <Image src={confirmPin} className='img-fluid' />
                    <ul className='listItem'>
                        <li>
                            A pop up  box will open & you will be asked to type and re-confirm your 4-digit PIN.
                            Once you complete this, click on the Confirm button to complete your PIN selection.
                        </li>
                        <li>
                            Congratulation! Your Card is now activated, and your PIN choice has been confirmed.
                        </li>
                        <li>
                            You are still required to enable card usage and set spending limits before you can 
                            use the card. <strong> Select the "Set Usage & Spending Limits" option for instructions
                            on how to complete those steps.</strong> 
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='spendingLimitsrightsideShape'>
                    <div className='spendingLimitssmallShape'></div>
                </Col>
            </Row>
         </Container>
    </>
  )
}
