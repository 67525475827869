import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <>
        <Container className='terms-condition' id='documents'>
            <Row>
                <Col md={12}>
                    <h1> Important Documents </h1>
                </Col>
            </Row>
        </Container>
        <Container className='terms'>
            <Row className=''>
                <Col md={8} className=''>
                    <div className='pdf-icons'>
                     <a href={require("../assets/MITC.pdf")} target='_blank' > 
                        <i class="fa-solid fa-file-pdf fa-2x"></i> 
                            <span> Please click here to download Most Important Terms & Conditions. </span> 
                        </a> 
                    </div>
                    <div className='pdf-icons'>
                    <a href={require("../assets/Code_of_Banks_Commitment_to_Customers_Eng.pdf")} target='_blank' > 
                        <i class="fa-solid fa-file-pdf fa-2x"></i> 
                            <span>Click here to download Code of Commitment for Customers.</span>
                    </a>
                    </div>
                    <div className='pdf-icons'>
                    <a href={require("../assets/Fair Practice Code for Credit Card Operations.pdf")} target='_blank' > 
                        <i class="fa-solid fa-file-pdf fa-2x"></i> 
                        <span>Click here to download Fair Practice Code for Credit Card Operations.</span>  
                    </a> 
                    </div>
                    
                </Col>
                <Col md={4} className='terms-condition__rightsideShape'>
                    <div className='terms-condition__smallShape'></div>
                </Col>
            </Row>
         </Container>

    </>
  )
}

export default TermsAndConditions