import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import cardsPage from '../assets/cards-pages.png'
import cardUnBlocking from '../assets/unblock.png'

const HowToActivateTwo = () => {
  return (
    <>
         <Container className='how-to-activate'>
            <Row>
                <Col md={12}>
                    <h1>How to <span className='cursiveText'>Activate:</span> </h1>
                    <h3>Dhi CUB Visa Signature Card</h3>
                </Col>
            </Row>
        </Container>
         <Container>
            <Row className='text-center'>
                <Col md={4} className='login'>
                    <h4>Cards Page</h4>
                    <Image src={cardsPage} className='img-fluid' />
                    <ul className='listItem'>
                        <li>
                            Once a card is chosen you will be  able to view card details. 
                        </li>
                        <li>
                            Click on the Activate Option for activating the Card.
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='profile-page'>
                    <h4>Card Un-Blocking</h4>
                    <Image src={cardUnBlocking} className='img-fluid' />
                    <ul className='listItem'>
                        <li>
                            A pop up dialogue box opens and you will be able to view 
                            the option to confirm card unblock.
                        </li>
                        <li>
                            Once the request is submitted you will see a pop up message
                            in the bottom of the screen confirming the card unblocking.
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='howToActivateSecondrightsideShape'>
                    <div className='howToActivateSecondsmallShape'></div>
                </Col>
            </Row>
         </Container>
    </>
  )
}

export default HowToActivateTwo