import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
import baner from '../assets/banner.png'

const HeroBanner = () => {
  return (
    <>
         <Container fluid>
            <Row className='text-center'>
                <Col md={6} className='banner-text'>
                    <h1>A Credit Card For</h1>
                    <h2>Everyone, Everything & <br /> Everywhere ! </h2>
                </Col>
                <Col md={6} className='banner-img'>
                  <Image src={baner} className='img-fluid' />
                </Col>
            </Row>
         </Container>
         <Container fluid>
            <Row className='welcome-message'>
                <Col md={8} className='left'>
                    <h1>Welcome</h1>
                    <p> 
                      We welcome you to the CUB Cards family and are excited to showcase 
                      benifits of our brand new card, Dhi CUB Visa Signature Card.
                    </p>
                </Col>
                <Col md={4}>
                {/* <Image src={crdslogo} className='img-fluid' /> */}
                </Col>
                {/* <Col md={3} sm={12} className='cards-logo'>
                <Image src={crdslogo} className='img-fluid' />
                </Col> */}
            </Row>
         </Container>
    </>
  )
}

export default HeroBanner