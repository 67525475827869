import React from 'react'
import { Button } from 'react-bootstrap';

const FloatingSignIn = () => {
  return (
    <div className='FloatingSignIn'>
        <Button>
            <a to=''> Log In </a>
        </Button>
    </div>
  )
}

export default FloatingSignIn