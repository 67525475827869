import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap';
// import login from '../assets/login-page.png'
import login from '../assets/newLogin.png'
import profile from '../assets/profile.png'


const HowToActivate = () => {
  return (
    <>
        <Container className='how-to-activate' id='loginInstructions'>
            <Row>
                <Col md={12}>
                    <h1>How to <span className='cursiveText'>Log in to:</span> </h1>
                    <h3>Dhi CUB Visa Signature Card</h3>
                </Col>
            </Row>
        </Container>
         <Container>
            <Row className='text-center'>
                <Col md={4} sm={12} xs={12} className='login'>
                    <h4>login page / menu</h4>
                    <Image src={login} className='img-fluid' />
                    <ul className='listItem'>
                        <li>
                            Go to the City Union Bank Mobile App and under the menu bar, click on the Credit 
                            Card option, then Credit Card Management button will open and by clicking on that 
                            you will be redirected to Credit Card page menu.
                        </li>
                        <li>
                            On the Credit Card page menu, click on Cards.
                        </li>
                    </ul>
                </Col>
                <Col md={4} sm={12} xs={12} className='profile-page'>
                    <h4>Cards Page</h4>
                    <Image src={profile} className='img-fluid' />
                    <ul className='listItem'>
                        {/* <li>
                            The Profile page shows the customer information and helps navigate to other pages such
                            as cards, personal information, account, and spending limits.
                        </li>
                        <li>
                            Click on the Cards option beside personal to view the available card. 
                        </li> */}
                        <li>
                            Click on the card available to unblock the card.
                        </li>
                    </ul>
                </Col>
                <Col md={4} className='howToActivateFirstrightsideShape'>
                    <div className='howToActivateFirstsmallShape'></div>
                </Col>
            </Row>
         </Container>

        
    </>
  )
}

export default HowToActivate