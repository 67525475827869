/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:83453dd6-36d2-4849-a913-654269f396e6",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_k8Mh8CK1i",
    "aws_user_pools_web_client_id": "7kn7om4up23vkj1vejkpfip57j",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://3qwaoz6mgbffpknqa23zy5o7qu.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-byv5sqmkqbcqpccntlf4zkujfi",
    "aws_cloud_logic_custom": [
        {
            "name": "cardsapi",
            "endpoint": "https://lg9kyr2bmj.execute-api.ap-south-1.amazonaws.com/devm",
            "region": "ap-south-1"
        }
    ]
};


export default awsmobile;
