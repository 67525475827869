import React, { useState } from 'react'
import { Container, Navbar, Nav, NavDropdown, Offcanvas, Image } from 'react-bootstrap';
import { Link } from 'react-scroll'

import logo from '../assets/cub-logo.jpg'
import '../App.css'
import '../Responsive.css'

const Header = () => {
  const [isOpen, setOpen] = useState(false);
const offsetValue = -90;
const [navbar, setNavbar] = useState(false)
const changeBackground = () => {
    if(window.scrollY >= 80) {
        setNavbar(true)
    } else {
        setNavbar(false)
    }
}

window.addEventListener('scroll', changeBackground)

return (
<>
  {[false, 'md', 'sm',].map((expand) => (
    <Navbar  className={navbar ? 'navbar active' : 'navbar'} key={expand} bg="light" expand={expand} fixed='top'  expanded={isOpen}>
      <Container fluid>
        <Navbar.Brand>
            <Link to="topsection" offset={offsetValue} spy={true} smooth={true} duration={500} onClick={() => setOpen(false)}>
              <Image src={logo} width="250px" />
            </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setOpen(isOpen ? false : "expanded")} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
         <Offcanvas.Header closeButton  onClick={() => setOpen(false)}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              <Link to="topsection" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)}>
                <Image src={logo} width="250px" />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
        <Offcanvas.Body>
          <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="m-auto newWrap">
                    <Nav.Link className='activeLink' >
                      <Link to="topsection" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)} >
                        Home
                      </Link>
                    </Nav.Link>
                    <NavDropdown title="Activation" id="collasible-nav-dropdown">
                        <NavDropdown.Item>
                          <Link to="loginInstructions" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)} >
                            Log In  Instructions
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link to="choosePin" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)} >
                            Choose Pin
                          </Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item>
                          <Link to="spendingLimits" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)} >
                            Set Usage & Spending Limits
                          </Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link>
                      <Link to="documents" spy={true} smooth={true} offset={offsetValue} duration={500} onClick={() => setOpen(false)} >Important Documents</Link>
                    </Nav.Link>
                </Nav>
                <Nav className='login-signup-area'>
                    {/* <Nav.Link className='login-area text-capitalize'>
                    Log in 
                    </Nav.Link> */}
                    
                    {/* <Nav.Link className='signup-area'>Sign Up</Nav.Link>
                    <span className='leads-sign-up-form'> (leads to the sign up form) </span> */}
                    
                    <span className='signup-area-design'></span> 
                </Nav>
            </Navbar.Collapse>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
      <span className='radiusRight'></span>
    </Navbar>
  ))}
</>
)
}

export default Header