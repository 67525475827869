import './App.css';
import './Responsive.css';
import Footer from './components/Footer';
import Header from './components/Header';
import FloatingSignIn from './components/FloatingSignIn';
import HeroBanner from './components/HeroBanner';
import HowToActivate from './components/HowToActivate';
import HowToActivateTwo from './components/HowToActivateTwo';
import {ChoosePin} from './components/ChoosePin';
import SpendingLimits from './components/SpendingLimits';
import TermsAndConditions from './components/TermsAndConditions';
import EmptyComponent from './components/EmptyComponent';

function App() {
  return (
    <>
      <EmptyComponent />
      <Header />
      <HeroBanner />
      <HowToActivate />
      <HowToActivateTwo />
      <ChoosePin />
      <SpendingLimits />
      <TermsAndConditions />
      <Footer />
      <FloatingSignIn />
      {/* <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /> */}
    </>  
  );
}

export default App;
