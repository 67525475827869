import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <>
        <Container fluid className='footer'>
            <Row className='text-center'>
                <Col md={12}>
                    <p className='pt-3'> &#169;Dhi CUB Visa Signature Card. All Rights Reserved. </p>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Footer